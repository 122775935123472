import { useEffect, useRef, useState } from 'react';

import { LiveTranscriptionEvents, createClient } from '@deepgram/sdk';
import { toast } from 'react-toastify';

import WarningToast from '@/components/toasts/warning-toast.component';

const useAudioTranscription = (initialMessage = '', onTranscriptionUpdate) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioLevels, setAudioLevels] = useState<number[]>(
    new Array(28).fill(0),
  );

  const deepgramLive = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef(null);
  const timeoutRef = useRef(null);
  const streamRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      audioContextRef.current = new window.AudioContext();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      const browserLanguage = navigator.language.split('-')[0] || 'en';

      const deepgram = createClient(
        process.env.NEXT_PUBLIC_DEEPGRAM_API_KEY || '',
      );

      deepgramLive.current = deepgram.listen.live({
        language: browserLanguage,
        model: 'nova-2',
        smart_format: true,
      });

      deepgramLive.current.on(LiveTranscriptionEvents.Open, () => {
        mediaRecorderRef.current.start(250);
        checkAudioLevel();

        timeoutRef.current = setTimeout(() => {
          stopRecording(true);
        }, 60000);
      });

      deepgramLive.current.on(LiveTranscriptionEvents.Transcript, (data) => {
        const newTranscript = data.channel.alternatives[0].transcript.trim();
        onTranscriptionUpdate((prevVal) => {
          const combinedText = (prevVal.trim() + ' ' + newTranscript).trim();
          return combinedText;
        });
      });

      deepgramLive.current.on(LiveTranscriptionEvents.Error, (err) => {
        console.error('Deepgram error:', err);
        toast(
          <WarningToast
            type="embed"
            detail={`Transcription error: ${err.message}`}
          />,
          {
            position: 'top-center',
            closeButton: false,
          },
        );

        stopRecording(true);
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (deepgramLive.current) {
          deepgramLive.current.send(event.data);
        }
      };

      setIsRecording(true);
      setIsSpeaking(true);
    } catch (err) {
      console.error('Error starting recording:', err);
      toast(
        <WarningToast
          type="embed"
          detail={`Failed to start recording: ${err.message}`}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );
    }
  };

  const stopRecording = (forceStop = false) => {
    if (forceStop || (!!mediaRecorderRef.current && isRecording)) {
      mediaRecorderRef?.current?.stop();
      if (deepgramLive.current) {
        deepgramLive.current.finish();
      }

      setIsRecording(false);
      setIsSpeaking(false);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }

      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        audioContextRef.current.close().catch(console.error);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const checkAudioLevel = () => {
    if (!analyserRef.current) return;

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    const normalizedLevels = Array.from(dataArray.slice(0, 28)).map(
      (value) => value / 255,
    );
    setAudioLevels(normalizedLevels);

    timeoutRef.current = setTimeout(checkAudioLevel, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        audioContextRef.current.close().catch(console.error);
      }
    };
  }, []);

  return {
    isRecording,
    isSpeaking,
    audioLevels,
    toggleRecording,
    stopRecording,
  };
};

export default useAudioTranscription;
