import * as React from 'react';
import { SVGProps } from 'react';

const SecurityCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 8.5 7.5 10 10 6.5M8 1.81A7.973 7.973 0 0 1 2.399 4 8.004 8.004 0 0 0 8 14.248a8.003 8.003 0 0 0 5.601-10.249H13.5A7.97 7.97 0 0 1 8 1.81Z"
    />
  </svg>
);
export default SecurityCheckIcon;
