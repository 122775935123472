import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const PlaneIcon: FC<SvgProps> = ({
  className,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1903_36345)">
        <path
          d="M5.14492 15.9975C5.01742 15.9975 4.91242 15.975 4.84492 15.945C4.63492 15.87 4.14742 15.6075 4.14742 14.73V11.0925L1.16992 9.2625C0.262415 8.7075 0.254915 8.0325 0.284915 7.77C0.322415 7.5075 0.517415 6.8625 1.53742 6.5775L15.3149 2.685C15.9824 2.4975 16.5674 2.6175 16.9349 3.03C17.2949 3.435 17.3549 4.035 17.0924 4.68L12.8849 14.895C12.8474 15 12.7799 15.12 12.6974 15.24C12.2249 15.885 11.3099 16.02 10.6649 15.5475L7.62741 13.5225L6.14992 15.42C5.78242 15.885 5.41492 15.9975 5.15242 15.9975H5.14492ZM7.46241 11.76C7.60491 11.76 7.74741 11.7975 7.87491 11.8875L11.5199 14.3175L15.6824 4.1475L1.99492 8.01L4.88242 9.7875L10.1674 6.7475C10.5124 6.5225 10.9774 6.6125 11.2099 6.9575C11.4349 7.3025 11.3449 7.7675 10.9999 8L5.65492 11.085V13.6125L6.86991 12.045C7.01991 11.8575 7.23741 11.7525 7.46241 11.7525V11.76Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1903_36345">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlaneIcon;
