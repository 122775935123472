'use client';

import type { SvgProps } from '@/components/icons/types';
import type {
  Platform,
  PlatformFormData,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';

import { FC, useState } from 'react';

import IntegrationCollapsibleItem from '@/components/integration-collapsible-item.component';

import AddRoutingPlatformForm from './add-routing-platform-form';
import IntercomIcon from '@/components/icons/intercom-icon.component';
import WhatsappIcon from '@/components/icons/whatsapp-icon.component';
import LineIcon from '@/components/icons/line-icon.component';
import MessengerIcon from '@/components/icons/messenger-icon.component';
import SmsIcon from '@/components/icons/sms-icon.component';
import TelegramIcon from '@/components/icons/telegram-icon.component';

type PlatformItemProps = PlatformFormData & {
  id: string;
  handleClickDelete?: (id: string) => void;
  handleClickUpdate: (id: string, data: PlatformFormData) => void;
};

export const PlatformIcons: { [x in Platform]: FC<SvgProps> } = {
  intercom: IntercomIcon,
  whatsapp: WhatsappIcon,
  line: LineIcon,
  messenger: MessengerIcon,
  sms: SmsIcon,
  telegram: TelegramIcon,
};

const PlatformItem: FC<PlatformItemProps> = ({
  id,
  platform,
  text,
  whatsappNumber,
  facebookPageId,
  telegramUsername,
  lineId,
  phoneNumber,
  handleClickUpdate,
  handleClickDelete,
}) => {
  const [isEditView, setIsEditView] = useState(false);

  const Icon = PlatformIcons[platform];

  const handleClickSave = (data: PlatformFormData) => {
    handleClickUpdate(id, data);
    setIsEditView(false);
  };

  const handleClickCancel = () => {
    setIsEditView(false);
  };

  return (
    <IntegrationCollapsibleItem
      id={id}
      title={platform}
      icon={<Icon />}
      isEditViewOpen={isEditView}
      handleToggleEditView={() => setIsEditView((prev) => !prev)}
      handleClickDelete={handleClickDelete}
    >
      <AddRoutingPlatformForm
        initialValue={{
          platform,
          text,
          whatsappNumber,
          facebookPageId,
          telegramUsername,
          lineId,
          phoneNumber,
        }}
        handleCancel={handleClickCancel}
        handleFormSubmit={handleClickSave}
        submitButtonText="Update"
      />
    </IntegrationCollapsibleItem>
  );
};

export default PlatformItem;
