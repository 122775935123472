import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const MessengerIcon: FC<SvgProps> = ({
  className,
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1623_34036)">
        <path
          d="M9.99962 1.74121C5.07356 1.74121 1.08026 5.43876 1.08026 10.0001C1.09166 11.2495 1.39461 12.4789 1.96502 13.5906C2.53542 14.7022 3.3575 15.6653 4.3658 16.4031V19.5802L7.35934 17.8912C8.21813 18.1359 9.10688 18.2596 9.99988 18.2587C14.9262 18.2587 18.9192 14.5609 18.9192 9.99981C18.9192 5.43876 14.9259 1.74121 9.99962 1.74121ZM10.9019 12.7913L8.6146 10.4078L4.23705 12.7913L9.04711 7.73704L11.3559 10.0788L15.6849 7.73704L10.9019 12.7913Z"
          fill="url(#paint0_linear_1623_34036)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1623_34036"
          x1="9.99975"
          y1="2.95426"
          x2="9.99975"
          y2="18.7953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00C6FF" />
          <stop offset="1" stop-color="#0068FF" />
        </linearGradient>
        <clipPath id="clip0_1623_34036">
          <rect width="20" height="20.0002" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessengerIcon;
