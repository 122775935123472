'use client';

import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import Button from '@/components/button.component';

import PencilIcon from '@heroicons/react/24/outline/PencilIcon';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';

type IntegrationCollapsibleItemProps = {
  id: string;
  icon: ReactNode;
  title: string;
  description?: string;
  isEditViewOpen?: boolean;
  showActiveBadge?: boolean;
  badge?: ReactNode;
  handleToggleEditView?: () => void;
  handleClickDelete?: (id: string) => Promise<void> | void;
  children?: ReactNode;
};

const IntegrationCollapsibleItem: FC<IntegrationCollapsibleItemProps> = ({
  id,
  icon,
  title,
  description,
  isEditViewOpen,
  showActiveBadge = true,
  badge = <IntegrationBadge />,
  handleToggleEditView,
  handleClickDelete,
  children,
}) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  return (
    <div
      className={cn('rounded-md overflow-hidden', {
        ['border border-gray-300']: isEditViewOpen,
      })}
    >
      <div
        className={cn(
          'flex items-center justify-between pl-5 py-[10px] pr-[10px] transition-colors',
          {
            ['bg-[#F5F5F5] shadow-sm']: !isEditViewOpen,
            ['bg-white border-b border-gray-300']: isEditViewOpen,
          },
        )}
      >
        <div className="flex items-center">
          {icon}
          <div className="ml-[10px]">
            <h6
              className="text-sm leading-5 font-medium capitalize"
              data-testid="integration-collapsible-item-title"
            >
              {title}
            </h6>
            <p className="text-xs text-gray-700">{description}</p>
          </div>
        </div>
        <div className="flex items-center  w-max">
          {showActiveBadge ? badge : null}

          <Button
            type="button"
            variant="light"
            size="sm"
            className="md:leading-5"
            handleClick={handleToggleEditView}
          >
            <PencilIcon className="w-4 h-4 mr-[6px] text-gray-500" />
            Edit
          </Button>
          {handleClickDelete && (
            <Button
              type="button"
              variant="light"
              size="sm"
              className="md:leading-5 ml-3"
              isLoading={isDeleteLoading}
              isDisabled={isDeleteLoading}
              handleClick={async () => {
                setIsDeleteLoading(true);
                await handleClickDelete(id);
                setIsDeleteLoading(false);
              }}
            >
              <TrashIcon className="w-4 h-4 mr-[6px] text-gray-500" />
              Delete
            </Button>
          )}
        </div>
      </div>
      {isEditViewOpen ? <div className="p-5">{children}</div> : null}
    </div>
  );
};

export default IntegrationCollapsibleItem;

const IntegrationBadge: FC = () => {
  return (
    <span className="text-xs leading-4 font-medium text-green-800 px-3 py-[3px] bg-green-100 rounded-xl mr-[10px] ring-1 ring-inset ring-green-800/10">
      Active
    </span>
  );
};
