import React, { useCallback, useMemo } from 'react';

const AudioVisualization = React.memo(
  ({ audioData }: { audioData: number[] }) => {
    const bars = useMemo(
      () => [
        { height: 10, color: '#047857' },
        { height: 4, color: '#047857' },
        { height: 14, color: '#047857' },
        { height: 12, color: '#047857' },
        { height: 10, color: '#34D399' },
        { height: 8, color: '#047857' },
        { height: 4, color: '#047857' },
        { height: 2, color: '#34D399' },
        { height: 14, color: '#047857' },
        { height: 4, color: '#047857' },
        { height: 12, color: '#34D399' },
        { height: 14, color: '#047857' },
        { height: 12, color: '#047857' },
        { height: 10, color: '#047857' },
        { height: 14, color: '#047857' },
        { height: 2, color: '#047857' },
        { height: 4, color: '#047857' },
        { height: 12, color: '#047857' },
        { height: 10, color: '#047857' },
        { height: 8, color: '#047857' },
        { height: 6, color: '#047857' },
        { height: 4, color: '#047857' },
        { height: 2, color: '#047857' },
        { height: 14, color: '#34D399' },
        { height: 12, color: '#047857' },
        { height: 10, color: '#047857' },
        { height: 8, color: '#047857' },
      ],
      [],
    );

    const getBarHeight = useCallback((level) => {
      const minHeight = 0.5;
      const threshold = 0.05;
      return level > threshold
        ? Math.max(minHeight, Math.min(14, level * 14))
        : minHeight;
    }, []);

    return (
      <svg
        width="146"
        height="14"
        viewBox="0 0 146 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {bars.map((bar, index) => {
          const height = getBarHeight(audioData[index] || 0);
          const y = 14 - height;

          return (
            <rect
              key={index}
              x={0.75 + index * 5.5}
              y={y}
              width={1.5}
              height={height}
              rx={height / 2}
              fill={bar.color}
            />
          );
        })}
      </svg>
    );
  },
);

AudioVisualization.displayName = 'AudioVisualization';

export default AudioVisualization;
