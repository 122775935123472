import { FC, ReactNode } from 'react';

import cn from 'classnames';

type MessageAreaProps = {
  children: ReactNode;
  className?: string;
};

const MessageArea: FC<MessageAreaProps> = ({ children, className }) => {
  return (
    <div
      id="messages"
      className={cn(
        'flex h-full flex-col-reverse overflow-auto bg-white p-[15px]',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default MessageArea;
