'use client';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import Input from '@/components/input.component';
import Select from '@/components/select.component';
import PhoneInput from '@/components/phone-input';
import { FC, useEffect } from 'react';

import type {
  Platform,
  PlatformFormData,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';
import { isValidPhoneNumber, type Value } from 'react-phone-number-input';
import Button from '../button.component';

export const schema = yup.object().shape({
  platform: yup.string().required('This field required'),
  text: yup.string().trim().required('This field required'),
  whatsappNumber: yup
    .string()
    .nullable()
    .when('platform', {
      is: 'whatsapp',
      then: (schema) =>
        schema
          .required('This field required')
          .test(
            'validPhoneNumber',
            'Phone number is not valid',
            function (item) {
              return typeof item === 'string'
                ? isValidPhoneNumber(item)
                : false;
            },
          ),
    }),
  facebookPageId: yup
    .string()
    .nullable()
    .when('platform', {
      is: 'messenger',
      then: (schema) => schema.trim().required('This field required'),
    }),
  telegramUsername: yup
    .string()
    .nullable()
    .when('platform', {
      is: 'telegram',
      then: (schema) => schema.trim().required('This field required'),
    }),
  lineId: yup
    .string()
    .nullable()
    .when('platform', {
      is: 'line',
      then: (schema) => schema.trim().required('This field required'),
    }),
  phoneNumber: yup
    .string()
    .nullable()
    .when('platform', {
      is: 'sms',
      then: (schema) =>
        schema
          .required('This field required')
          .test(
            'validPhoneNumber',
            'Phone number is not valid',
            function (item) {
              return typeof item === 'string'
                ? isValidPhoneNumber(item)
                : false;
            },
          ),
    }),
});

type PlatformOptions = Array<{
  value: Platform;
  label: string;
}>;

type AddRoutingPlatformFormProps = {
  initialValue?: PlatformFormData;
  options?: PlatformOptions;
  submitButtonText: string;
  handleCancel: () => void;
  handleFormSubmit: (data: PlatformFormData) => void;
};

export const platformOptions: PlatformOptions = [
  { value: 'intercom', label: 'Intercom' },
  { value: 'whatsapp', label: 'Whatsapp' },
  { value: 'line', label: 'Line' },
  { value: 'messenger', label: 'Messenger' },
  { value: 'sms', label: 'SMS' },
  { value: 'telegram', label: 'Telegram' },
];

const AddRoutingPlatformForm: FC<AddRoutingPlatformFormProps> = ({
  initialValue,
  submitButtonText,
  options = platformOptions,
  handleCancel,
  handleFormSubmit,
}) => {
  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: initialValue
      ? {
          ...initialValue,
        }
      : {
          platform: options[0].value,
        },
  });

  const platform = watch('platform');

  const handleClickSave = handleSubmit((data: PlatformFormData) => {
    handleFormSubmit(data);
  });

  const handleClickCancel = () => {
    reset(
      initialValue || {
        platform: 'intercom',
        text: '',
        whatsappNumber: null,
      },
    );
    handleCancel();
  };

  useEffect(() => {
    if (initialValue) reset(initialValue);
  }, [reset, initialValue]);

  return (
    <>
      <Select
        className="mb-5"
        label="Select platform"
        placeholder="Select platform"
        options={options}
        {...register('platform')}
        error={errors?.platform}
        helpText={
          platform === 'intercom'
            ? 'Intercom script must be added on your site'
            : null
        }
      />

      <Input
        className="mb-5"
        label="Routing message"
        placeholder="Talk with agent"
        {...register('text')}
        error={errors?.text}
      />

      {platform === 'whatsapp' ? (
        <PhoneInput
          className="mb-5"
          label="Phone number"
          id="whatsappNumber"
          placeholder="Add phone number"
          error={errors?.whatsappNumber}
          value={watch('whatsappNumber') as Value}
          onChange={(value) => {
            setValue('whatsappNumber', value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
        />
      ) : null}

      {platform === 'messenger' ? (
        <Input
          className="mb-5"
          label="Facebook page ID"
          placeholder="Add Facebook page ID"
          {...register('facebookPageId')}
          error={errors?.facebookPageId}
        />
      ) : null}

      {platform === 'telegram' ? (
        <Input
          className="mb-5"
          label="Telegram username"
          placeholder="Add Telegram username"
          {...register('telegramUsername')}
          helpText="Without @ symbol in front of username (e.g. aibot)"
          error={errors?.telegramUsername}
        />
      ) : null}

      {platform === 'line' ? (
        <Input
          className="mb-5"
          label="Line ID"
          placeholder="Add line ID"
          {...register('lineId')}
          error={errors?.lineId}
        />
      ) : null}

      {platform === 'sms' ? (
        <PhoneInput
          className="mb-5"
          label="Phone number"
          id="phoneNumber"
          placeholder="Add your phone number"
          error={errors?.phoneNumber}
          value={watch('phoneNumber') as Value}
          onChange={(value) => {
            setValue('phoneNumber', value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
        />
      ) : null}

      <div className="flex items-center justify-end">
        <Button
          type="button"
          className="w-max"
          variant="light"
          handleClick={handleClickCancel}
        >
          Cancel
        </Button>

        <Button
          type="button"
          className="w-max ml-3"
          variant="dark"
          handleClick={handleClickSave}
        >
          {submitButtonText}
        </Button>
      </div>
    </>
  );
};

export default AddRoutingPlatformForm;
