import * as React from 'react';
import { SVGProps } from 'react';

const PauseComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#DC2626"
      fillRule="evenodd"
      d="M18 9.5a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9Zm-6.23 3.461a.693.693 0 0 0 .691-.692V6.731a.693.693 0 0 0-.692-.693h-.692a.692.692 0 0 0-.692.693v5.538a.693.693 0 0 0 .692.692h.692Zm-4.847 0a.692.692 0 0 0 .692-.692V6.731a.693.693 0 0 0-.692-.693h-.692a.692.692 0 0 0-.693.693v5.538a.693.693 0 0 0 .693.692h.692Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PauseComponent;
