import postcss from 'postcss';
import postcssNested from 'postcss-nested';

export const processNestedCss = (nestedCSS: string) => {
  const processor = postcss([postcssNested]);
  let css = nestedCSS;
  try {
    css = processor.process(nestedCSS).css;
  } catch {
    css = nestedCSS;
  }

  return css;
};
