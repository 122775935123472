'use client';

import { FC, ReactNode } from 'react';
import { useColorPicker } from 'react-best-gradient-color-picker';
import { getTextColor } from '@/utils/colors';

type MessengerButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
};

const MessengerButton: FC<MessengerButtonProps> = ({
  children,
  onClick,
  backgroundColor,
}) => {
  const { valueToHex, rgbaArr } = useColorPicker(backgroundColor, () => {});
  const hexString = valueToHex();
  const textColor = getTextColor(hexString);
  const rgbArr = rgbaArr.slice(0, 3);

  return (
    <button
      className="flex items-center py-2 px-6 rounded-full text-sm leading-5 font-medium transition-transform hover:-translate-y-1"
      style={{
        background: backgroundColor || '#1C86FA',
        color: textColor || '#fff',
        boxShadow:
          rgbArr && !!rgbArr.length
            ? `0px 3px 15px 0px rgba(${rgbArr?.join(', ')}, 0.5)`
            : 'none',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MessengerButton;
