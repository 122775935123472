import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const PlusCircleIcon: FC<SvgProps> = ({
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <g clipPath="url(#clip0_2981_286)">
        <path
          d="M9.75 5.25H8.25V8.25H5.25V9.75H8.25V12.75H9.75V9.75H12.75V8.25H9.75V5.25ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2981_286">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusCircleIcon;
