import { FC, ReactNode } from 'react';
import cn from 'classnames';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import ExclamationCircleIcon from '@heroicons/react/24/outline/ExclamationCircleIcon';

type ErrorToastProps = {
  title?: string;
  detail: string | ReactNode;
  type?: 'embed' | 'default';
};
const ErrorToast: FC<ErrorToastProps> = ({
  title,
  detail,
  type = 'default',
}) => {
  return (
    <div
      className={cn(
        'pointer-events-auto w-full overflow-hidden rounded-lg bg-[#FFD1D1] shadow-lg ring-1 ring-red-700 ring-opacity-5',
        {
          ['max-w-sm']: type === 'default',
          ['max-w-[75%] m-auto']: type === 'embed',
        },
      )}
    >
      <div
        className={cn('relative', {
          ['p-4']: type === 'default',
          ['p-3']: type === 'embed',
        })}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon
              className="h-6 w-6 text-gray-900"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            {title && (
              <p className="text-sm font-medium text-gray-900 mb-1">{title}</p>
            )}
            <div className="text-sm text-gray-700">{detail}</div>
          </div>
        </div>
        {type !== 'embed' && (
          <div className="absolute right-4 top-4">
            <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorToast;
