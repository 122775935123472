import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const TelegramIcon: FC<SvgProps> = ({
  className,
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1623_34053)">
        <path
          d="M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8079 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92894C15.6725 1.53041 13.8907 0.578004 11.9509 0.192152C10.0111 -0.193701 8.00043 0.00433283 6.17317 0.761209C4.3459 1.51809 2.78412 2.79981 1.6853 4.4443C0.58649 6.08879 0 8.02219 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
          fill="#34AADF"
        />
        <path
          d="M4.16162 9.91166C4.16162 9.91166 9.16162 7.85908 10.8957 7.13713C11.5604 6.84819 13.8166 5.92334 13.8166 5.92334C13.8166 5.92334 14.8571 5.51877 14.7703 6.50124C14.7415 6.90584 14.5102 8.32211 14.2789 9.85364C13.9319 12.0215 13.5561 14.393 13.5561 14.393C13.5561 14.393 13.4985 15.0577 13.007 15.1734C12.5156 15.289 11.7064 14.7688 11.562 14.6531C11.4463 14.5663 9.39451 13.2657 8.64097 12.6301C8.56452 12.5784 8.5024 12.5081 8.4604 12.4258C8.4184 12.3436 8.39789 12.2521 8.40076 12.1598C8.40364 12.0675 8.42982 11.9774 8.47686 11.898C8.52389 11.8185 8.59026 11.7522 8.66979 11.7053C9.71029 10.7516 10.9529 9.56669 11.7045 8.81513C12.0514 8.46816 12.398 7.65896 10.9545 8.64183C8.9019 10.0581 6.87932 11.3875 6.87932 11.3875C6.67107 11.4816 6.44597 11.5326 6.21753 11.5376C5.98908 11.5425 5.762 11.5013 5.54987 11.4164C4.68147 11.1562 3.67137 10.8093 3.67137 10.8093C3.67137 10.8093 2.97783 10.3751 4.1628 9.91325L4.16162 9.91166Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1623_34053">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TelegramIcon;
