import * as React from 'react';
import { SVGProps } from 'react';

const MicrophoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#777"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 15.625a5 5 0 0 0 5-5v-1.25m-5 6.25a5 5 0 0 1-5-5v-1.25m5 6.25v3.125m-3.125 0h6.25M10 13.125a2.5 2.5 0 0 1-2.5-2.5V3.75a2.5 2.5 0 1 1 5 0v6.875a2.5 2.5 0 0 1-2.5 2.5Z"
    />
  </svg>
);
export default MicrophoneIcon;
